(function ($) {
  'use strict';

  Drupal.behaviors.skuSelectV1 = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /** Sets up events for this template.
     *  @type {Object} context - the DOM scope, usually document.
     */
    setEvents: function (context) {
      var self = this;

      function triggerSelect($option) {
        var skuId = $option.val();
        var sku = prodcat.data.getSku(skuId);
        var skuBaseId;

        if (sku) {
          skuBaseId = sku.SKU_BASE_ID;
          $option.closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
        }
      }

      $(document).on('click', '.js-sku-menu-chip__option', function () {
        var $selectedOption = $(this);
        $selectedOption.parent().children().removeClass('selected');
        $selectedOption.addClass('selected');
        triggerSelect($selectedOption);
      });

      // When the menu changes, trigger skuSelect:
      $(document).on('change', 'select.js-sku-menu', function () {
        var $selectedOption = $(this);

        triggerSelect($selectedOption);
      });
    },

    /**
     * Standard method for attaching the template's JS.
     * @param {Object} context - the DOM scope, usually document.
     * @param {Object} settings - useful collection of site info.
     */
    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      this.setEvents(context);
    }
  };
})(jQuery);
